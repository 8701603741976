import React, { Component } from 'react'; //different
import './App.css';

class App extends Component {


    onClick = (e) => {
        e.preventDefault()
        ////console.log('onclick..')
    }
    render() {
        return (
            <div>
             
            </div>

        );
    }
}
export default App;
