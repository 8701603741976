
import './App.css';
import React, { Component } from 'react';

function App() {
    return (
        <div className="x-index-pre-footer-wrapper">
        <div className="-top-wrapper">
          <div className="container-fluid -top-content-container" data-animatable="fadeInUp">
            <div className="container">
              <div className="-heading-wrapper -normal offset-lg-3 col-lg-6">
                <h3 className="-title">Deepluss Casino เว็บไซต์พนันออนไลน์ยอดนิยม <br />
                  บริการคาสิโนที่ดีที่สุด</h3>
              </div>
            </div>
            <img src="/build/web/igame-index-lobby-wm/img/wm-casino-gameplay-device.png" className="-img-middle-element img-fluid" alt="Deepluss casino demo gameplay on real devices" width={800} height={471} />
          </div>
          <div className="container">
            <div className="-heading-wrapper -custom">
              <h3 className="-title">Deepluss Casino เว็บไซต์พนันออนไลน์ยอดนิยม <br /> บริการคาสิโนที่ดีที่สุด
              </h3>
            </div>
            <div className="-articles-wrapper">
              <div className="-flip-container" data-animatable="fadeInNotOpacity">
                <div className="-flipper -no-01">
                  <div className="-front card -inner-wrapper" style={{ backgroundImage: "url(build/web/igame-index-lobby-wm/img/wm-casino-card-backward.png)" }}>
                  </div>
                  <div className="-back card -inner-wrapper" style={{ backgroundImage: "url(build/web/igame-index-lobby-wm/img/wm-casino-card-forward.png)" }}>
                    <div className="card-header -ic-article-top-container">
                      <img src="/build/web/igame-index-lobby-wm/img/wm-casino-article-icon-1.png" alt="" className="-ic-article-top -ic-01 img-fluid" />
                    </div>
                    <div className="card-body -description-wrapper">
                      <h3 className="-title">Deepluss คาสิโน <br /> สำหรับทุกคน</h3>
                      <p className="-detail">โต๊ะให้เลือกมากที่สุด ภาพชัด <br /> เสถียร์
                        ไร้ปัญหาจุกจิก</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="-flip-container" data-animatable="fadeInNotOpacity">
                <div className="-flipper -no-02">
                  <div className="-front card -inner-wrapper" style={{ backgroundImage: "url(build/web/igame-index-lobby-wm/img/wm-casino-card-backward.png)" }}>
                  </div>
                  <div className="-back card -inner-wrapper" style={{ backgroundImage: "url(build/web/igame-index-lobby-wm/img/wm-casino-card-forward.png)" }}>
                    <div className="card-header -ic-article-top-container">
                      <img src="/build/web/igame-index-lobby-wm/img/wm-casino-article-icon-2.png" alt="" className="-ic-article-top -ic-02 img-fluid" />
                    </div>
                    <div className="card-body -description-wrapper">
                      <h3 className="-title">เล่น Deepluss <br /> ต้องเล่นเว็บตรง</h3>
                      <p className="-detail">เราคือเว็บตรง #1 เจ้าเดียวในเอเชีย <br />
                        ผู้นำระบบออโต้ ฝ่ายบริการมืออาชีพ</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="-flip-container" data-animatable="fadeInNotOpacity">
                <div className="-flipper -no-03">
                  <div className="-front card -inner-wrapper" style={{ backgroundImage: "url(build/web/igame-index-lobby-wm/img/wm-casino-card-backward.png)" }}>
                  </div>
                  <div className="-back card -inner-wrapper" style={{ backgroundImage: "url(build/web/igame-index-lobby-wm/img/wm-casino-card-forward.png)" }}>
                    <div className="card-header -ic-article-top-container">
                      <img src="/build/web/igame-index-lobby-wm/img/wm-casino-article-icon-3.png" alt="" className="-ic-article-top -ic-03 img-fluid" />
                    </div>
                    <div className="card-body -description-wrapper">
                      <h3 className="-title">Deepluss.bet, <br /> The place for bet</h3>
                      <p className="-detail">เว็บดียอดผู้เล่นสูง เพลินเพลิน <br /> มั่นใจ
                        กับแหล่งเดิมพันของคุณ</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="-articles-wrapper-mobile">
              <div className="-content-wrapper-mobile">
                <h3 className="-title">Deepluss คาสิโน สำหรับทุกคน</h3>
                <p className="-detail">โต๊ะให้เลือกมากที่สุด ภาพชัด<br />เสถียร์ ไร้ปัญหาจุกจิก</p>
              </div>
              <div className="-content-wrapper-mobile">
                <h3 className="-title">เล่น Deepluss ต้องเล่นเว็บตรง</h3>
                <p className="-detail">เราคือเว็บตรง #1 เจ้าเดียวในเอเชีย<br />ผู้นำระบบออโต้
                  ฝ่ายบริการมืออาชีพ</p>
              </div>
              <div className="-content-wrapper-mobile">
                <h3 className="-title">Deepluss.bet, The place for bet</h3>
                <p className="-detail">เว็บดียอดผู้เล่นสูง เพลินเพลิน <br /> มั่นใจ
                  กับแหล่งเดิมพันของคุณ</p>
              </div>
            </div>
          </div>
          <img src="/build/web/igame-index-lobby-wm/img/ic-chip-01.png" alt="Deepluss Casino chip 01" className="-ic-chip-01 img-fluid" />
          <img src="/build/web/igame-index-lobby-wm/img/ic-chip-02.png" alt="Deepluss Casino chip 02" data-animatable="fadeInLeft" data-delay={400} className="-ic-chip-02 img-fluid" />
          <img src="/build/web/igame-index-lobby-wm/img/ic-chip-03.png" alt="Deepluss Casino chip 03" data-animatable="fadeInRight" data-delay={400} className="-ic-chip-03 img-fluid" />
          <img src="/build/web/igame-index-lobby-wm/img/ic-chip-04.png" alt="Deepluss Casino chip 04" data-animatable="fadeInLeft" data-delay={400} className="-ic-chip-04 img-fluid" />
        </div>
        <div className="-bottom-container" data-bgset="/build/web/igame-index-lobby-wm/img/wm-casino-brick-bg.jpg">
          <div className="container">
            <div className="-sub-description-inner-wrapper">
              <div className="row">
                <div className="col-md-6 -img-box" data-animatable="fadeInUp" style={{ backgroundImage: 'url(build/web/igame-index-lobby-wm/img/wm-casino-effect-1.png)' }}>
                  <img src="/build/web/igame-index-lobby-wm/img/wm-casino-mobile-banner.png" alt="Deepluss casino banner" className="-img -img-fluid" />

                </div>
                <div className="col-md-6 -content-container">
                  <div className="-content-wrapper">
                    <span className="-sub-title">สนุก และ คว้าชัยชนะได้ทุกที่ทุกเวลา</span>
                    <p className="-description">แพลตฟอร์ม คาสิโน สปอร์ต และ เกมพนัน <br /> ให้คุณสามารถเลือกได้ในแบบที่คุณต้องการ</p>
                  </div>
                  <div className="-content-wrapper">
                    <span className="-sub-title">ความลงตัวของเทคโนโลยี และ งานบริการ</span>
                    <p className="-description">ด้วยฝ่ายบริการลูกค้าคุณภาพ 24 ชั่วโมง <br /> และระบบฝากถอนแสนง่าน อัจฉริยะ ที่ใครๆ ก็ว๊าว</p>
                  </div>
                  <div className="-banner-icon-wrapper">
                    <img src="/build/web/igame-index-lobby-wm/img/footer-service-banner-1.png" alt="คาสิโนออนไลน์ บาคาร่าออนไลน์ สล็อตออนไลน์ ให้บริการตลอด 24 ชั่วโมง" data-animatable="fadeInUp" data-delay="200" className="-ic-banner" />
                    <img src="/build/web/igame-index-lobby-wm/img/footer-service-banner-2.png" alt="คาสิโนออนไลน์ บาคาร่าออนไลน์ สล็อตออนไลน์ ให้บริการตลอด 24 ชั่วโมง" data-animatable="fadeInUp" data-delay="400" className="-ic-banner" />
                    <img src="/build/web/igame-index-lobby-wm/img/footer-service-banner-3.png" alt="คาสิโนออนไลน์ บาคาร่าออนไลน์ สล็อตออนไลน์ ให้บริการตลอด 24 ชั่วโมง" data-animatable="fadeInUp" data-delay="600" className="-ic-banner" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="x-question-and-answer-container">
          <div className="container">
            <h4 className="-title">คำถามที่พบบ่อย Deepluss Casino</h4>
            <div className="-accordion-list-wrapper accordion" id="questionAndAnswerAccordion">
              <article className="-accordion-list-item-wrapper">
                <h4 className="-btn -show" data-toggle="collapse" data-target="#wm-casino-popular-game" aria-controls="wm-casino-popular-game">
                  เกมที่ได้รับความนิยมมากที่สุดใน Deepluss Casino?
                  <i className="fas fa-plus" />
                  <i className="fas fa-minus" />
                </h4>
                <div id="wm-casino-popular-game" className="collapse show js-q-and-a-article-content" aria-labelledby="headingOne" data-parent="#questionAndAnswerAccordion">
                  <p className="-description">บาคาร่าเป็นเกมที่ได้รับความนิยมมากที่สุด
                    อย่างไรก็ตาม Deepluss Casino มีเกมมากมายที่คุณชื่นชอบให้เลือกสรร
                    ตั้งแต่เกมสุดคลาสสิคอย่างรูเล็ตและแบล็คแจ็คไปจนถึงเกมที่ได้รับความนิยมสูงในเอเชียอย่างไฮโลและเสือมังกร
                  </p>
                </div>
              </article>
              <article className="-accordion-list-item-wrapper">
                <h5 className="-btn collapsed" data-toggle="collapse" data-target="#wm-casino-commission" aria-controls="wm-casino-commission">
                  Deepluss Casino ให้ค่าคอมมิชชั่นไเท่าไหร่?
                  <i className="fas fa-plus" />
                  <i className="fas fa-minus" />
                </h5>
                <div id="wm-casino-commission" className="collapse js-q-and-a-article-content" aria-labelledby="headingOne" data-parent="#questionAndAnswerAccordion">
                  <p className="-description">Deepluss.bet casino ให้คอมมิชชั่นมากกว่าที่ใด เพราะ มอบ 5%
                    ทุกยอดได้ หรือ ยอดเสีย ทุปสัปดาห์</p>
                </div>
              </article>
              <article className="-accordion-list-item-wrapper">
                <h5 className="-btn collapsed" data-toggle="collapse" data-target="#wm-casino-register"  aria-controls="wm-casino-register">
                  สมัครสมาชิกกับ Deepluss Casino ได้อย่างไร?
                  <i className="fas fa-plus" />
                  <i className="fas fa-minus" />
                </h5>
                <div id="wm-casino-register" className="collapse js-q-and-a-article-content" aria-labelledby="headingOne" data-parent="#questionAndAnswerAccordion">
                  <p className="-description">การสมัครสมาชิกกับ Deepluss Casino สามารถทำผ่าน 3
                    ขั้นตอนง่ายๆ ดังนี้
                    1. กรอกเบอร์โทรศัพท์มือถือของคุณให้ถูกต้อง<br />
                    2. รอรับ SMS ยืนยัน จากนั้นกรอกรหัส OTP ที่ได้รับให้ถูกต้อง
                    พร้อมตั้งรหัสผ่านเพื่อเข้าเล่นเกม<br />
                    3. ใส่หมายเลขบัญชีและชื่อบัญชีให้ถูกต้อง และสามารถเข้าร่วมสนุกกับ Deepluss
                    Casino ได้เลยทันที!</p>
                </div>
              </article>
              <article className="-accordion-list-item-wrapper">
                <h5 className="-btn collapsed" data-toggle="collapse" data-target="#wm-casino-promotion"  aria-controls="wm-casino-promotion">
                  มีโปรโมชั่นอะไรบ้างที่ Deepluss Casino นำเสนอ?
                  <i className="fas fa-plus" />
                  <i className="fas fa-minus" />
                </h5>
                <div id="wm-casino-promotion" className="collapse js-q-and-a-article-content" aria-labelledby="headingOne" data-parent="#questionAndAnswerAccordion">
                  <p className="-description">Deepluss Casino มีโปรโมชั่นหลากหลายให้เลือก โปรดี โปรแรง
                    สามารถศึกษาโปรโมชั่นเพิ่มเติมได้ <a href="promotions.html">ที่นี่</a>
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      

      </div>
    );
}

export default App;
